import axios from 'axios';
const api = axios.create({
  //baseURL: 'https://api-dev.emissionsreductionnow.com',
  baseURL: 'https://api-staging.emissionsreductionnow.com',
  headers: { 'Content-Type': 'application/json' },
  params: { /*appVersion: app.expo.version*/ },
});

/*api.interceptors.request.use(request => {
  console.log('Starting Request', JSON.stringify(request, null, 2));
  return request;
});

api.interceptors.response.use(response => {
  console.log('Response:', JSON.stringify(response, null, 2));
  return response;
});*/

export default api;
