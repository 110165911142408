import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import ReactLoading from 'react-loading';
import { AiOutlineMinusCircle } from "react-icons/ai";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { readFile, writeFileXLSX, utils } from 'xlsx';
//context
import UserContext from '../context/UserContext';
//api
import aws from '../api/aws';
//components
import { NumberKgCO2e } from '../components/KgCO2e';
import { ButtonFull, ButtonEmpty } from '../components/Buttons';
import { ButtonOption, RadioOption } from '../components/ToolBarOption';
import { OrderArrows } from '../components/OrderArrows';
import { useInterval } from '../components/useInterval';
//util
import { colors, colorValuesRecipes } from '../util/values';
import { commonStyles } from '../util/styles';
import { sortBy, getColor, getMonthDate, toLowerNoAccents } from '../util/climatecook';

const SalesDataScreen = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [items, setItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);
  const [search, setSearch] = useState("");
  const [orderType, setOrderType] = useState("dateNull");
  const [isOrderAsc, setIsOrderAsc] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModalImportSuccess, setShowModalImportSuccess] = useState(false);
  const [showModalImport, setShowModalImport] = useState(false);
  const [showModalLength, setShowModalLength] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const [deleteId, setDeleteId] = useState(-1);
  const [hover, setHover] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [clickPosition, setClickPosition] = useState(-1);
  const [error1, setError1] = useState('');
  const [error2, setError2] = useState('');
  const [error3, setError3] = useState('');
  const [error4, setError4] = useState('');
  const [errorArray1, setErrorArray1] = useState([]);
  const [errorArray2, setErrorArray2] = useState([]);
  const [errorArray3, setErrorArray3] = useState([]);
  const [errorArray4, setErrorArray4] = useState([]);
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(0);
  const firstUpdate = useRef(true);

  const handleMouseEnter = (which) => {
    setHover(which);
  };

  const handleMouseLeave = () => {
    setHover("");
  };

  useEffect(() => {
    getSales();
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    showItems();
  }, [isOrderAsc, orderType]);

  useInterval(
    () => {
      if (showModalImportSuccess || progress === 100) {
        setShowModalImportSuccess(true);
        getSales();
        setShowModalImport(false);
        setProgress(0);
      } else {
        setProgress((oldProgress) => Math.min(oldProgress + step, 100));
      }
    },
    showModalImport ? 500 : null
  );

  const getSales = async () => {
    //let now = new Date((new Date()).getTime() + 86400000);
    //let dateTo = now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
    const response = await aws.get('/stats/sales', { params: { owner: user[0].user_id, dateFrom: '2010-01-01', dateTo: '2050-01-01' } });
    if (response.data) {
      let newItems = [];
      for (let item of response.data) {
        newItems.push({ ...item, date: (item.date != null ? item.date : null) });
      }
      setItems(newItems);
      newItems = setOrder(isOrderAsc, newItems);
      setVisibleItems(newItems);
    }
    setIsReady(true);
  };

  const onEnterPressed = (key) => {
    if (key === 'Enter') {
      showItems();
    }
  };

  const toggleOrder = (type) => {
    setIsOrderAsc(!isOrderAsc);
    setOrderType(type);
  };

  const showItems = () => {
    let newItems = [];
    for (let i = 0; i < items.length; i++) {
      let name = i18n.language === 'fr' ? items[i].name_fra : items[i].name_eng;
      name = name === null ? "" : name;
      name = toLowerNoAccents(name);
      let searchInput = toLowerNoAccents(search);
      if (name.includes(searchInput)) {
        newItems.push(items[i]);
      }
    }

    newItems = setOrder(isOrderAsc, newItems);
    setVisibleItems(newItems);
    setIsReady(true);
  };

  const setOrder = (order, itemList) => {
    let newRecipes = [];
    if (orderType === 'name') {
      newRecipes = itemList.sort(sortBy(i18n.language === 'fr' ? 'name_fra' : 'name_eng', order));
    } else if (orderType === 'portions') {
      newRecipes = itemList.sort(sortBy('total_portions', order, false, Number));
    } else if (orderType === 'date') {
      newRecipes = itemList.sort(sortBy('date', order));
    } else if (orderType === 'dateNull') {
      newRecipes = itemList.sort(sortBy('dateNull', order));
    }
    return newRecipes;
  };

  const onImport = async (url) => {
    setShowModalImport(true);
    setErrorArray1([]);
    setErrorArray2([]);
    setErrorArray3([]);
    setErrorArray4([]);
    if (url != null) {
      const file = await (await fetch(URL.createObjectURL(url))).arrayBuffer();
      const workbook2 = readFile(file, { cellDates: true });
      const first_sheet2 = workbook2.Sheets[workbook2.SheetNames[0]];
      const rows = utils.sheet_to_json(first_sheet2, { header: ['name', 'portions', 'date', 'traiteur'] });
      if (isNaN(rows[0].portions)) {
        rows.splice(0, 1);
      }
      if (rows.length > 30000) {
        setShowModalImport(false);
        setShowModalLength(true);
        document.getElementById("uploadSalesButton").value = "";
        return;
      }
      let dateErrors = [];
      let quantityErrors = [];
      let nameErrors = [];
      for (let i = 0; i < rows.length; i++) {
        let tempName = rows[i].name + (rows[i].traiteur && rows[i].traiteur !== '' ? ' - ' + rows[i].traiteur : '');
        let date = new Date(rows[i].date);
        if (date instanceof Date && !isNaN(date)) {
          rows[i].date = getMonthDate(date);
        } else {
          dateErrors.push(tempName);
        }
        if (isNaN(rows[i].portions)) {
          quantityErrors.push(tempName);
        }
      }

      //check for duplicates
      const allSales = await aws.get('/stats/sales', { params: { which: 'duplicate', owner: user[0].user_id } });
      let duplicateErrors = [];
      rows.forEach(r => {
        if (allSales.data.some(s => toLowerNoAccents(s.name) === toLowerNoAccents(r.name) && s.portions === r.portions && s.date === r.date && (r.traiteur === '' || s.vendor == null || toLowerNoAccents(s.vendor) === toLowerNoAccents(r.traiteur)))) {
          duplicateErrors.push(r.name);
        }
      });

      const allIngs = await aws.get('/ingredients', { params: { type: 'yields', owner: user[0].user_id, lang: i18n.language } });
      let newRows = [];
      for (let r of rows) {
        let exists = false;
        for (let nr of newRows) {
          if (r.name === nr.name && r.date === nr.date && r.traiteur === nr.traiteur) {
            exists = true;
            nr.portions += r.portions;
            break;
          }
        }
        if (!exists) {
          if (r.name === 'Biscuit canneberges') {
            console.log(allIngs.data.find(ing => toLowerNoAccents(r.name) === toLowerNoAccents(i18n.language === 'fr' ? ing.name_fra : ing.name_eng) && ing.owner === user[0].user_id && (r.traiteur === '' || ing.vendor == null || toLowerNoAccents(ing.vendor) === toLowerNoAccents(r.traiteur)) && ing.is_recipe === 1));
          }
          //if name of recipe doesnt exist in db -> error
          if (!allIngs.data.some(ing => toLowerNoAccents(r.name) === toLowerNoAccents(i18n.language === 'fr' ? ing.name_fra : ing.name_eng) && ing.owner === user[0].user_id && (r.traiteur === '' || ing.vendor == null || toLowerNoAccents(ing.vendor) === toLowerNoAccents(r.traiteur)) && ing.is_recipe === 1)) {
            let tempName = r.name + (r.traiteur && r.traiteur !== '' ? ' - ' + r.traiteur : '');
            if (!nameErrors.includes(tempName))
              nameErrors.push(tempName);
          }
          newRows.push({ name: r.name, date: r.date, portions: r.portions, traiteur: r.traiteur });
        }
      }

      if (nameErrors.length > 0) {
        setErrorArray1(nameErrors);
        setError1('lblUnknownName');
      }
      if (dateErrors.length > 0) {
        setError2('lblUnknownDate');
        setErrorArray2(dateErrors);
      }
      if (quantityErrors.length > 0) {
        setError3('lblUnknownQuantity');
        setErrorArray3(quantityErrors);
      }
      if (duplicateErrors.length > 0) {
        setError4('lblUnknownDuplicate');
        setErrorArray4(duplicateErrors);
      }
      if (nameErrors.length > 0 || dateErrors.length > 0 || quantityErrors.length > 0 || duplicateErrors.length > 0) {
        setShowModalImport(false);
        setShowModal(true);
        document.getElementById("uploadSalesButton").value = "";
        return;
      }

      let totalSec = (0.037 * newRows.length) + 10;
      totalSec = totalSec > 900 ? 900 : totalSec;
      setStep(100 / (2 * totalSec));

      let response = await aws.post('/stats/sales', { owner: user[0].user_id, data: newRows });
      if (response.data) {
        setShowModalImportSuccess(true);
        getSales();
        setShowModalImport(false);
        setProgress(0);
      }
      document.getElementById("uploadSalesButton").value = "";
    }
  };

  const onExport = async () => {
    const response = await aws.get('/stats/sales', { params: { which: 'export', owner: user[0].user_id } });
    if (response.data) {
      let rows = [];
      response.data.forEach((j) => {
        rows.push({
          name: j.name,
          traiteur: j.traiteur,
          categorie: j.categorie,
          date: j.date,
          portions: j.total_portions,
          co2: (j.co2 / j.portions * j.total_portions).toFixed(3),
          'co2/portion': (j.co2 / j.portions).toFixed(3),
          threshold_low: j.threshold_low ?? colorValuesRecipes.ingredientLow,
          threshold_high: j.threshold_high ?? colorValuesRecipes.ingredientHigh,
        });
      });
      const workbook = utils.book_new();
      const worksheet = utils.json_to_sheet(rows);
      utils.book_append_sheet(workbook, worksheet, 'Sales');

      writeFileXLSX(workbook, "Sales.xlsx", { bookType: "xlsx", compression: true });
    }
  };

  const onSelect = (e, id) => {
    let inBetweenIds = [];
    let newDeleteIds = [...deleteIds];
    if (!e.shiftKey) {
      setClickPosition(id);
      inBetweenIds.push(id);
    } else if (clickPosition !== -1) {
      let add = false;
      for (let i = 0; i < visibleItems.length; i++) {
        if (clickPosition === visibleItems[i].id || id === visibleItems[i].id) {
          add = !add;
          inBetweenIds.push(visibleItems[i].id);
        } else if (add) {
          inBetweenIds.push(visibleItems[i].id);
        }
      }
    }

    if (newDeleteIds.includes(id)) {
      for (let ib of inBetweenIds) {
        if (newDeleteIds.includes(ib)) {
          newDeleteIds.splice(newDeleteIds.indexOf(ib), 1);
        }
      }
    } else {
      for (let ib of inBetweenIds) {
        if (!newDeleteIds.includes(ib)) {
          newDeleteIds.push(ib);
        }
      }
    }

    setDeleteIds(newDeleteIds);
  };

  const onDeleteMultiple = () => {
    if (deleteIds.length > 0) {
      setShowDeleteModal(true);
    }
  };

  const onDeleteSingle = (event, id) => {
    event.stopPropagation();
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = async () => {
    let newDeleteIds = deleteId !== -1 ? [deleteId] : deleteIds;
    if (newDeleteIds.length > 0) {
      const response = await aws.delete('/stats/sales', { data: { ids: newDeleteIds } });
      if (response.data.affectedRows > 0) {
        getSales();
        setDeleteId(-1);
        setDeleteIds([]);
      }
    }
    setShowDeleteModal(false);
  };

  const downloadModel = () => {
    fetch('https://backend-dev.emissionsreductionnow.com/xlsx/importModelSales.xlsx')
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = "Modèle d'importation des ventes 202401.xlsx";
          a.click();
        });
      });
  };

  const renderItemList = (itemList) => {
    return (
      <div>
        <table style={styles.table}>
          <tbody>
            {visibleItems.map((item) => {
              let itemCo2 = item.co2 / item.portions;
              if (item.co2 === null) {
                itemCo2 = null;
              }
              let itemColor = getColor(itemCo2, 1, item.threshold_high, item.threshold_low);
              if (item.portions === null) {
                return;
              } else {
                return (
                  <tr key={item.id} style={{ userSelect: 'none', cursor: 'pointer', backgroundColor: (hover === item.id || deleteIds.includes(item.id)) ? colors.semiLightGrey : colors.lightGrey, transition: 'background-color 0.1s ease-out' }}
                    onMouseEnter={() => handleMouseEnter(item.id)}
                    onMouseLeave={handleMouseLeave}
                    onClick={(e) => onSelect(e, item.id)} >
                    <td style={{ ...styles.firstCell, backgroundColor: itemColor }}></td>
                    <td style={styles.gap}></td>
                    <td style={styles.cell}>{i18n.language === "fr" ? item.name_fra : item.name_eng}</td>
                    <td style={{ ...styles.cell, width: 178, color: colors.text }}>{item.total_portions}</td>
                    <td style={{ ...styles.cell, width: 200, color: colors.text }}>{item.date}</td>
                    <td style={styles.lastCell}>
                      <AiOutlineMinusCircle size={24} color={colors.delete} onClick={(event) => onDeleteSingle(event, item.id)} />
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
        {
          itemList.length > 0 ? null :
            <div style={{ marginTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ width: '70%', fontSize: 25, fontWeight: 'bold', color: colors.main, textAlign: 'center' }}>{t("textEmptyRecipes")}</div>
            </div>
        }
      </div >

    );
  };

  return (
    <div style={commonStyles.container}>
      {showDeleteModal ? <div style={styles.modalWindow}>
        <div style={{ ...styles.modalDiv, width: 200 }}>
          <div style={{ ...styles.modalText, marginBottom: 20 }}>{deleteId === -1 && deleteIds.length > 1 ? t('lblDeleteManyRecipe') : t('lblDeleteRecipe')}</div>
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnNo')} onButtonClick={() => { setShowDeleteModal(false); setDeleteId(-1) }} />
            <ButtonEmpty text={t('btnYes')} onButtonClick={() => onConfirmDelete()} />
          </div>
        </div>
      </div> : null}
      {showModalLength ? <div style={styles.modalWindow}>
        <div style={styles.modalDiv}>
          <div style={styles.modalText}>{t('lblTooMuchRows')}</div>
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnOk')} onButtonClick={() => setShowModalLength(false)} />
          </div>
        </div>
      </div> : null}
      {showModal ? <div style={styles.modalWindow}>
        <div style={{ ...styles.modalDiv, maxHeight: 600, overflowY: 'scroll' }}>
          {errorArray1.length > 0 ? <>
            <div style={styles.modalText}>{t(error1)}</div>
            <div style={styles.modalTextMonths}>
              {errorArray1.map((i, index) => <div key={index + i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray2.length > 0 ? <>
            <div style={styles.modalText}>{t(error2)}</div>
            <div style={styles.modalTextMonths}>
              {errorArray2.map((i, index) => <div key={index + i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray3.length > 0 ? <>
            <div style={styles.modalText}>{t(error3)}</div>
            <div style={styles.modalTextMonths}>
              {errorArray3.map((i, index) => <div key={index + i}>{i}</div>)}
            </div>
          </> : null}
          {errorArray4.length > 0 ? <>
            <div style={styles.modalText}>{t(error4)}</div>
            <div style={styles.modalTextMonths}>
              {errorArray4.map((i, index) => <div key={index + i}>{i}</div>)}
            </div>
          </> : null}
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnOk')} onButtonClick={() => setShowModal(false)} />
          </div>
        </div>
      </div> : null}
      {showModalImportSuccess ? <div style={styles.modalWindow}>
        <div style={styles.modalDiv}>
          <div style={styles.modalText}>{t('lblImportSuccess')}</div>
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnOk')} onButtonClick={() => setShowModalImportSuccess(false)} />
          </div>
        </div>
      </div> : null}
      {showModalImport ? <div style={styles.modalWindow}>
        <div style={styles.modalDiv}>
          <div style={styles.modalText}>{t('lblImport')}</div>
          <div style={styles.modalButtons}>
            <Box sx={{ width: '100%', mt: 1 }}>
              <LinearProgress variant="determinate" value={progress} sx={{ color: colors.main }} />
            </Box>
          </div>
        </div>
      </div> : null}
      <div style={commonStyles.title}>{t('routeSalesData')}</div>
      <div style={commonStyles.body}>
        <div style={styles.searchContainer}>
          <div style={styles.searchBar}>
            <InputBase
              sx={{ pl: 1, flex: 1 }}
              placeholder={t('btnSearch')}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              onKeyPress={(event) => onEnterPressed(event.key)}
            />
            <IconButton type="button" sx={{ p: 0 }} onClick={() => showItems()}>
              <SearchIcon style={{ color: colors.main }} />
            </IconButton>
          </div>
          <div style={{ marginRight: 16 }} />
          <input id="uploadSalesButton" type="file" accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(event) => { onImport(event.target.files[0]) }} hidden />

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ButtonFull text={t('btnImportFile')} onButtonClick={() => document.getElementById("uploadSalesButton").click()} />
            <a style={hover === "1" ? styles.linkHover : styles.link} onMouseEnter={() => handleMouseEnter("1")} onMouseLeave={() => handleMouseLeave("")} onClick={() => downloadModel()}>{t('lblImportModel')}</a>
          </div>
        </div>
        <div style={{ display: 'flex', margin: '10px 26px 30px 26px' }}>
          <div style={styles.toolBar}>
            <ButtonOption text={t("lblExport")} onButtonClick={() => onExport()} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', position: 'sticky', top: 0, backgroundColor: colors.background, zIndex: 50 }}>
          <div style={{ display: 'flex', margin: '10px 26px 0px 26px' }}>
            <div style={{ ...styles.toolBar, overflow: 'hidden', height: deleteIds.length > 0 ? 20 : 0, transition: 'height 0.2s ease-out' }}>
              <ButtonOption width={260} text={t("lblDeleteSelected")} onButtonClick={() => onDeleteMultiple()} color={colors.bad} />
            </div>
          </div>
          <table style={styles.table}>
            <tbody>
              <tr key="header">
                <th style={{ padding: 0, width: 8 }}></th>
                <th style={{ padding: 0, width: 8 }}></th>
                <th style={{ ...styles.headerCell, cursor: 'pointer', width: 404 }} onClick={() => toggleOrder("name")}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <OrderArrows id="name" name={t('lblSoldItem')} stateType={orderType} stateIsAsc={isOrderAsc} />
                  </div>
                </th>
                <th style={{ ...styles.headerCell, width: 178 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>{t('lblnumberOfPortions')}</div>
                </th>
                <th style={{ ...styles.headerCell, cursor: 'pointer', width: 200 }} onClick={() => toggleOrder("date")}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <OrderArrows id="date" name={t('lblDate')} stateType={orderType} stateIsAsc={isOrderAsc} />
                  </div>
                </th>
                <th></th>
              </tr>
            </tbody>
          </table>
        </div>
        {!isReady ?
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactLoading type="spin" color={colors.main} height={40} width={40} />
          </div> : renderItemList(items)
        }
      </div>
    </div>
  );
};

const styles = {
  searchContainer: {
    margin: '20px 20px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  searchBar: {
    height: 32,
    display: 'flex',
    justifyContent: 'stretch',
    padding: '0 12px',
    flex: 1,
    border: '1px solid lightgrey',
    borderRadius: 12,
    backgroundColor: colors.lightGrey
  },
  toolBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },

  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px'
  },
  headerCell: {
    padding: '0 20px',
    fontWeight: 400,
    textAlign: 'left',
    color: colors.text
  },
  cell: {
    padding: '10px 20px',
    fontSize: 16,
    fontWeight: '500',
  },
  firstCell: {
    width: 6,
  },
  gap: {
    width: 6,
    backgroundColor: colors.background,
  },
  lastCell: {
    width: 80,
    textAlign: 'center',
    backgroundColor: colors.background
  },
  modalWindow: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  },
  modalDiv: {
    width: 350,
    padding: '30px 40px',
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: colors.main,
    borderWidth: 2,
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  modalText: {
    marginBottom: 0,
    textAlign: 'center'
  },
  modalTextMonths: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    textAlign: 'center',
    color: colors.bad
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-around'
  },

  modalDivCategories: {
    position: 'absolute',
    padding: '15px 20px',
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: colors.grey,
    borderWidth: 1,
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'column',
  },
  modalCheckbox: {
    display: 'flex',
    alignItems: 'center'
  },
  modalCheckboxText: {
    fontSize: 14,
  },
  link: {
    marginTop: 10,
    color: colors.link,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  linkHover: {
    marginTop: 10,
    color: colors.grey,
    textDecoration: 'none',
    cursor: 'pointer'
  },
};

export default SalesDataScreen;
